<template>
  <div>
    <b-tabs
      v-if="$ability.can('manage', 'User')"
      fill
      pills
      class="shadow p-2"
    >
      <div class="mb-2">
        <b-link
          :to="{ name: 'startup-portal-details' }"
          class="btn btn-outline-primary btn-cart mr-2"
        >
          <plus-icon size="16" />
          Add Startup
        </b-link>
        <b-modal
          id="incubator-modal"
          cancel-title="Cancel"
          title="Select Startup"
          no-close-on-backdrop
        >
          <v-select
            v-model="selectedOrgId"
            class="flex-fill"
            :close-on-select="true"
            :get-option-label="option => option.title"
            :options="startupOrgs"
            :reduce="option => option.users_associationtables[0].id"
            placeholder="Select Startup"
            style="cursor:pointer"
            @input="$router.replace(`/startup-portal/incubator/${selectedOrgId}/manage`)"
          />
        </b-modal>
      </div>
      <div>
        <b-row>
          <b-col md="4">
            <b-card>
              <div class="d-flex justify-content-between ">
                <b-card-text>
                  <strong>
                    Startup Programs
                  </strong>
                </b-card-text>
                <feather-icon
                  size="20"
                  icon="GridIcon"
                />
              </div>
              <div class="d-flex align-items-start">
                <b-card-title
                  class="mr-2"
                  style="font-size: 2rem"
                >
                  <strong>22345</strong>
                </b-card-title>
              </div>
              <div>
                <b-card-text>Open for Registration : 23</b-card-text>
                <div class="d-flex justify-content-between align-items-start">
                  <b-card-text>My Applications : 23</b-card-text>
                  <b-link to="/startup-portal-program">
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="20"
                    />
                  </b-link>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card>
              <div class="d-flex justify-content-between ">
                <b-card-text>
                  <strong>
                    Events
                  </strong>
                </b-card-text>
                <feather-icon
                  size="20"
                  icon="ActivityIcon"
                />
              </div>
              <div class="d-flex align-items-start">
                <b-card-title
                  class="mr-2"
                  style="font-size: 2rem"
                >
                  <strong>222</strong>
                </b-card-title>
              </div>
              <div>
                <b-card-text>Open for Registration : 23</b-card-text>
                <div class="d-flex justify-content-between align-items-start">
                  <b-card-text>My Applications : 23</b-card-text>
                  <b-link to="/startup-portal-events">
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="20"
                    />
                  </b-link>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card>
              <div class="d-flex justify-content-between ">
                <b-card-text>
                  <strong>
                    Competitions
                  </strong>
                </b-card-text>
                <feather-icon
                  size="20"
                  icon="ZapIcon"
                />
              </div>
              <div class="d-flex align-items-start">
                <b-card-title
                  class="mr-2"
                  style="font-size: 2rem"
                >
                  <strong>1845</strong>
                </b-card-title>
              </div>
              <div>
                <b-card-text>Open for Registration : 23</b-card-text>
                <div class="d-flex justify-content-between align-items-start">
                  <b-card-text>My Applications : 23</b-card-text>
                  <b-link to="/startup-portal-competition">
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="20"
                    />
                  </b-link>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <div class="d-flex justify-content-between mb-2">
        <h2>My Programs</h2>
        <b-link
          :to="`/startup-portal/programs`"
          class="btn btn-outline-primary"
        >
          <chevrons-right-icon size="16" />
        </b-link>
      </div>
      <b-overlay
        v-if="programs.length > 0"
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        :show="$apollo.loading"
        spinner-variant="primary"
        >
        <b-card
          v-if="programs.length < 1"
          class="text-center"
        >
          No programs found under your organizations!
        </b-card>
        <section class="grid-view">
          <b-row>
            <b-col
              v-for="(program, i) in programs"
              :key="i"
              :title="program.title"
              md="4"
              style="margin-bottom: 10px"
            >
              <b-card
                :key="i"
                header-tag="header"
                tag="article"
                style="border-radius: 10px; margin-bottom: 0; height: 100%;"
              >
                <b-img
                  class="rounded"
                  src="https://picsum.photos/600/300/?image=25"
                  alt="image"
                  img-top
                  style="width: 100%; position: relative;"
                />
                <b-badge
                  style="position: absolute; top: 70px; right: 30px;color: black;"
                  variant="light"
                >
                  {{ program.industry }}
                </b-badge>
                <b-badge
                  style="position: absolute; top: 100px; right: 30px;color: black;"
                  variant="light"
                >
                  Free
                </b-badge>
                <b-badge
                  style="position: absolute; top: 70px; left: 30px;color: black;"
                  variant="light"
                >
                  P
                </b-badge>
                <div class="d-flex align-items-center mt-1 ">
                  <b-img
                    rounded
                    alt="Rounded image"
                    src="https://shorturl.at/kxFUX"
                    style="height: 30px; width: 30px; margin-right: 10px;"
                  />
                  <b-card-text> <strong>Startup Name</strong></b-card-text>
                </div>
                <template #header>
                  <h6 class="d-flex align-items-center justify-content-center  mb-0 text-center">
                    {{ program.type }}
                  </h6>
                </template>
                <div class="mt-2 mb-1">
                  <b-card-title>
                    {{ program.title }}
                  </b-card-title>
                  <b-card-text>
                    Some quick example text to build on the card title and make up the bulk of the card's content.
                  </b-card-text>
                  <hr>
                  <div class="d-flex align-items-baseline   justify-content-between">
                    <b-card-text><strong>Date : {{ beginDate(program.begin_date) }}</strong></b-card-text>
                    <b-badge class="badge badge-light-success">
                      Online
                    </b-badge>
                  </div>
                  <b-card-text><strong>Duration : {{ (program.duration) }}</strong></b-card-text>
                  <b-card-text><strong>Capacity of Program : {{ (program.capacity) }}</strong></b-card-text>

                </div>
                <b-card-footer>
                  <div class="item-options text-center d-flex">
                    <b-link
                      class="btn btn-wishlist btn-light mr-1 "
                      style="width:100%"
                      variant="light"
                      @click="$bvModal.show('view-program-modal')"
                    >
                      <!-- :to="`/startup-portal/programs/${program.id}`" -->
                      <span>View Details</span>
                    </b-link>
                    <b-link
                      :to="`/startup-portal/programs/select/${program.id}`"
                      class="btn btn-primary btn-cart ml-1 "
                      style="width:100%"
                    >
                      <span>Open</span>
                    </b-link>
                  </div>
                </b-card-footer>
              </b-card>
            </b-col>
          </b-row>
        </section>
      </b-overlay>
      <hr class="my-2">
      <div class="d-flex justify-content-between mb-2">
        <h2>My Upcoming Events</h2>
        <b-link
          :to="`/startup-portal/events`"
          class="btn btn-outline-primary"
        >
          <chevrons-right-icon size="16" />
        </b-link>
      </div>
      <b-overlay
        v-if="programs.length > 1"
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card
          v-if="programs.length < 1"
          class="text-center"
        >
          No programs found under your organizations!
        </b-card>
        <section class="grid-view">
          <b-row>
            <b-col
              v-for="(program, i) in programs"
              :key="i"
              md="4"
            >
              <b-card
                tag="article"
                header-tag="header"
                class="ecommerce-card"
              >
                <b-img
                  class="rounded"
                  src="https://picsum.photos/600/300/?image=25"
                  alt="image"
                  img-top
                  style="width: 100%; position: relative;"
                />
                <b-badge
                  style="position: absolute; top: 80px; right: 30px;color: black;"
                  variant="light"
                >
                  Event Type
                </b-badge>
                <b-badge
                  style="position: absolute; top: 110px; right: 30px;color: black;"
                  variant="light"
                >
                  Free
                </b-badge>
                <b-badge
                  style="position: absolute; top: 80px; left: 30px;color: black;"
                  variant="light"
                >
                  E
                </b-badge>
                <template #header>
                  <div class="d-flex align-items-center">
                    <b-img
                      rounded
                      alt="Rounded image"
                      src="https://shorturl.at/kxFUX"
                      style="height: 30px; width: 30px; margin-right: 10px;"
                    />
                    <b-card-text> <strong>Startup Name</strong></b-card-text>
                  </div>
                </template>
                <!-- <b-container sm="" style="position: absolute; top: 40%; left: 8px;" >
                        <b-img rounded alt="Rounded image" src="https://shorturl.at/kxFUX" style="height: 70px; width: 140px; margin-right: 10px;"></b-img>
                  </b-container> -->
                <div class="mt-2 mb-1">
                  <div>
                    <b-card-text style="font-size: large; margin-top: 15px; margin-bottom: 5px;">
                      <strong> {{ program.title }} </strong>
                    </b-card-text>
                  </div>
                  <div class="d-flex align-items-baseline   justify-content-between">
                    <b-card-text><strong>Date : {{ beginDate(program.begin_date) }}</strong></b-card-text>
                    <b-badge class="badge badge-light-success">
                      Online
                    </b-badge>
                  </div>
                  <b-card-sub-title class="mt-1">
                    Some quick example text to build on the card title and make up the bulk of the card's content.
                  </b-card-sub-title>
                  <hr>
                </div>
                <div class="item-options text-center d-flex">
                  <b-link
                    class="btn btn-wishlist btn-light mr-1 "
                    style="width:100%"
                    variant="light"
                    @click="$bvModal.show('view-event-modal')"
                  >
                    <!-- :to="`/startup-portal/programs/${program.id}`" -->
                    <span>View Details</span>
                  </b-link>
                  <!-- :to="`/events/${program.id} /manage`" -->
                  <b-link
                    :to="`/startup-portal/events/select/${program.id}`"
                    class="btn btn-primary btn-cart ml-1 "
                    style="width:100%"
                  >
                    <span>Open</span>
                  </b-link>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </section>
      </b-overlay>
      <hr class="my-2">
      <div class="d-flex justify-content-between mb-2">
        <h2>My Upcoming Competitions</h2>
        <b-link
          :to="`/startup-portal/competition`"
          class="btn btn-outline-primary"
        >
          <chevrons-right-icon size="16" />
        </b-link>
      </div>
      <b-overlay
        v-if="applynow.length > 1"
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card
          v-if="applynow.length < 1"
          class="text-center"
        >
          No programs are currently open for application!
        </b-card>
        <section class="grid-view">
          <b-row>
            <b-col
              v-for="(program, i) in programs"
              :key="i"
              :title="program.title"
              md="4"
              style="margin-bottom: 10px"
            >
              <b-card
                :key="i"
                img-src="https://picsum.photos/600/300/?image=25"
                img-alt="Image"
                img-top
                class="ecommerce-card"
                tag="article"
                style="border-radius: 10px; margin-bottom: 0; height: 100%;  position: relative;"
              >
                <b-badge
                  style="position: absolute; top: 10px; right: 10px;color: black;"
                  variant="light"
                >
                  Type of Competition
                </b-badge>
                <b-badge
                  style="position: absolute; top: 40px; right: 10px;color: black;"
                  variant="light"
                >
                  Free
                </b-badge>
                <b-badge
                  style="position: absolute; top: 10px; left: 10px;color: black;"
                  variant="light"
                >
                  C
                </b-badge>
                <div class="d-flex align-items-center ">
                  <b-img
                    rounded
                    alt="Rounded image"
                    src="https://shorturl.at/kxFUX"
                    style="height: 30px; width: 30px; margin-right: 10px;"
                  />
                  <b-card-text> <strong>Startup Name</strong></b-card-text>
                </div>
                <div class="mt-2 mb-1">
                  <b-card-title>
                    {{ program.title }}
                  </b-card-title>
                  <div class="d-flex align-items-baseline   justify-content-between">
                    <b-card-text><strong>Date : {{ beginDate(program.begin_date) }}</strong></b-card-text>
                    <b-badge class="badge badge-light-success">
                      Online
                    </b-badge>
                  </div>
                  <b-card-text>
                    Some quick example text to build on the card title and make up the bulk of the card's content.
                  </b-card-text>
                </div>
                <div class="item-options text-center d-flex">
                  <b-link
                    class="btn btn-wishlist btn-light mr-1 "
                    style="width:100%"
                    variant="light"
                    @click="$bvModal.show('view-upcoming-competitions-modal')"
                  >
                    <!-- :to="`/startup-portal/programs/${program.id}`" -->
                    <span>View Details</span>
                  </b-link>
                  <b-link
                    :to="`/startup-portal/competitions/select/${program.id}`"
                    class="btn btn-primary btn-cart ml-1 "
                    style="width:100%"
                  >
                    <span>Open</span>
                  </b-link>
                </div>
                <b-card-footer class="text-center p-1  mt-1">
                  <strong>Cash Prize · Prize Pool : INR 1Lakh</strong>
                </b-card-footer>
              </b-card>
            </b-col>
          </b-row>
        </section>
      </b-overlay>
    </b-tabs>
    <b-card
      v-else-if="$ability.can('manage', 'Mentor')"
      fill
      pills
      class="shadow p-2"
    >
      <div>
        <b-row>
          <b-col md="4">
            <b-card class="border-secondary ">
              <div class="d-flex justify-content-between ">
                <b-card-text>
                  <strong>
                    Startup Programs
                  </strong>
                </b-card-text>
                <feather-icon
                  size="20"
                  icon="GridIcon"
                />
              </div>
              <div class="d-flex align-items-start">
                <b-card-title
                  class="mr-2"
                  style="font-size: 2rem"
                >
                  <strong>2345</strong>
                </b-card-title>
              </div>
              <div>
                <b-card-text>Mentor Invitations : 23</b-card-text>
                <b-card-text>Open for Registration : 23</b-card-text>
                <div class="d-flex justify-content-between align-items-start">
                  <b-card-text>My Applications : 23</b-card-text>
                  <b-link to="/mentor-portal/programs">
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="20"
                    />
                  </b-link>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card class="border-secondary ">
              <div class="d-flex justify-content-between ">
                <b-card-text>
                  <strong>
                    Events
                  </strong>
                </b-card-text>
                <feather-icon
                  size="20"
                  icon="ActivityIcon"
                />
              </div>
              <div class="d-flex align-items-start">
                <b-card-title
                  class="mr-2"
                  style="font-size: 2rem"
                >
                  <strong>222</strong>
                </b-card-title>
              </div>
              <div>
                <b-card-text>Mentor Invitations : 23</b-card-text>
                <b-card-text>Open for Registration : 23</b-card-text>
                <div class="d-flex justify-content-between align-items-start">
                  <b-card-text>My Applications : 23</b-card-text>
                  <b-link to="/mentor-portal/events">
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="20"
                    />
                  </b-link>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card class="border-secondary ">
              <div class="d-flex justify-content-between ">
                <b-card-text>
                  <strong>
                    Competitions
                  </strong>
                </b-card-text>
                <feather-icon
                  size="20"
                  icon="ZapIcon"
                />
              </div>
              <div class="d-flex align-items-start">
                <b-card-title
                  class="mr-2"
                  style="font-size: 2rem"
                >
                  <strong>1845</strong>
                </b-card-title>
              </div>
              <div>
                <b-card-text>Mentor Invitations : 23</b-card-text>
                <b-card-text>Open for Registration : 23</b-card-text>
                <div class="d-flex justify-content-between align-items-start">
                  <b-card-text>My Applications : 23</b-card-text>
                  <b-link to="/mentor-portal/competitions">
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="20"
                    />
                  </b-link>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <div class="d-flex justify-content-between mb-2">
        <h2>My Programs</h2>
        <b-link
          :to="`/mentor-portal/programs/my-programs`"
          class="btn btn-outline-primary"
        >
          <chevrons-right-icon size="16" />
        </b-link>
      </div>
      <b-overlay
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card
          v-if="mentorPrograms.length < 1"
          class="text-center"
        >
          No programs found under your organizations!
        </b-card>

        <section class="ecommerce-application">
          <div class="grid-view">
            <opportunities-card
              v-for="(program, i) in mentorPrograms"
              :key="i"
              :opportunities-type="'program'"
              :program="program"
              :tab="'dashboard'"
            />
          </div>
        </section>
      </b-overlay>
      <hr class="my-2">
      <div class="d-flex justify-content-between mb-2">
        <h2>My Events</h2>
        <b-link
          :to="`/mentor-portal/events/my-events`"
          class="btn btn-outline-primary"
        >
          <chevrons-right-icon size="16" />
        </b-link>
      </div>
      <b-overlay
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card
          v-if="mentorEvents.length < 1"
          class="text-center"
        >
          No events found under your organizations!
        </b-card>
        <section class="ecommerce-application">
          <div class="grid-view">
            <opportunities-card
              v-for="(program, i) in mentorEvents"
              :key="i"
              :opportunities-type="'events'"
              :program="program"
              :tab="'dashboard'"
            />
          </div>
        </section>
      </b-overlay>
      <hr class="my-2">
      <div class="d-flex justify-content-between mb-2">
        <h2>My Competitions</h2>
        <b-link
          :to="`/mentor-portal/competitions/my-competitons`"
          class="btn btn-outline-primary"
        >
          <chevrons-right-icon size="16" />
        </b-link>
      </div>
      <b-overlay
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card
          v-if="mentorCompetitions.length < 1"
          class="text-center"
        >
          No competitions found under your organizations!
        </b-card>
        <section class="ecommerce-application">
          <div class="grid-view">
            <opportunities-card
              v-for="(program, i) in mentorCompetitions"
              :key="i"
              :opportunities-type="'competitions'"
              :program="program"
              :tab="'dashboard'"
            />
          </div>
        </section>
      </b-overlay>
    </b-card>
    <incubator-dashboard
      v-else-if="$ability.can('manage', 'Incubator')"
    />
    <span v-else>Some error occured. Please try logging out and log in again. </span>
    <b-modal
      id="view-program-modal"
      title="Title"
      hide-footer
      size="lg"
    >
      <b-img
        src="https://picsum.photos/600/300/?image=25"
        style="width: 100%;"
      />
      <b-table
        stacked
        bordered
        :items="items"
      >
        <template #cell(url)="data">
          <b-link
            :href="prependHttps(data.item.url)"
            target="_blank"
          >{{ data.item.url }}
          </b-link>
        </template>
      </b-table>
    </b-modal>
    <b-modal
      id="view-event-modal"
      title="Event Title"
      hide-footer
      size="lg"
    >
      <b-img
        src="https://picsum.photos/600/300/?image=25"
        style="width: 100%;"
      />
      <b-table
        stacked
        bordered
        :items="eventItems"
      >
        <template #cell(url)="data">
          <b-link
            :href="prependHttps(data.item.url)"
            target="_blank"
          >{{ data.item.url }}
          </b-link>
        </template>
      </b-table>
    </b-modal>
    <b-modal
      id="view-upcoming-competitions-modal"
      title="Competition Title"
      hide-footer
      size="lg"
    >
      <b-img
        src="https://picsum.photos/600/300/?image=25"
        style="width: 100%;"
      />
      <b-table
        stacked
        bordered
        :items="compItems"
      >
        <template #cell(url)="data">
          <b-link
            :href="prependHttps(data.item.url)"
            target="_blank"
          >{{ data.item.url }}
          </b-link>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>
<script>
import { getUserData } from '@/auth/utils'
import { ChevronsRightIcon, PlusIcon } from 'vue-feather-icons'
import OpportunitiesCard from '@/views/mentor-portal/opportunities/components/OpportunitiesCard.vue'
import gql from 'graphql-tag'
import {
  BBadge,
  BCard,
  BCardFooter,
  BCardSubTitle,
  BCardText,
  BCardTitle,
  BCol,
  BImg,
  BLink,
  BModal,
  BOverlay,
  BRow,
  BTable,
  BTabs,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    IncubatorDashboard: () => import('@/views/dashboard/v1/pages/IncubatorDashboard.vue'),
    BTabs,
    BCard,
    BCardText,
    OpportunitiesCard,
    BCardFooter,
    BTable,
    // BCardBody,
    BCardTitle,
    BCardSubTitle,
    // BMedia,
    // BMediaAside,
    // BMediaBody,
    BBadge,
    BImg,
    BOverlay,
    BLink,
    BCol,
    BRow,
    BModal,
    ChevronsRightIcon,
    PlusIcon,
    // CompassIcon,
    vSelect,
  },
  beforeRouteLeave(to, from, next) {
    if (to.meta.resource === 'Auth' || getUserData().associatedOrgDetails) {
      next()
    }
    next(false)
  },
  data() {
    return {
      data: [
        {
          icon: 'ClockIcon',
          color: 'light-warning',
          title: 'Pre-Incubatee',
          total: null,
        },
        {
          icon: 'ActivityIcon',
          color: 'light-primary',
          title: 'Incubatee',
          total: null,
        },
        {
          icon: 'CheckIcon',
          color: 'light-success',
          title: 'Graduate',
          total: null,
        },
        {
          icon: 'UsersIcon',
          color: 'light-secondary',
          title: 'Network',
          total: null,
        },
      ],
      programs: [],
      slide: 0,
      sliding: null,
      applynow: [],
      items: [
        {
          'Organized By': 'xyz', 'Title ': 'Startup India', 'Type ': 'Online', 'Sector ': 'xyz', 'Funding Assitance': 'xyz', 'Lifecycle Stage': 'xyz', 'Start Date & Time': '12 sept 2023', 'End Date & Time': '24 sept 2023', 'Duration ': 1000,
        },
      ],
      eventItems: [
        {
          'Organized By': 'xyz', 'Event Title': 'Startup India', 'Event Type': 'Online', 'Description ': 'this is a description this is a description this is a description this is a description this is a description this is a description this is a description', 'Start Date & Time': '12 sept 2023', 'End Date & Time': '24 sept 2023',
        },
      ],
      compItems: [
        {
          'Organized By': 'xyz', 'Competition Title': 'Startup India', 'Competetion Type': 'Online', 'Description ': 'this is a description this is a description this is a description this is a description this is a description this is a description this is a description', 'Start Date & Time': '12 sept 2023', 'End Date & Time': '24 sept 2023', 'Competetion Prize': 1000, 'Prize Question 1': 'question competition 1', 'Prize Question 2': 'question competition 2',
        },
      ],
      applications: [],
      mentorPrograms: [],
      mentorCompetitions: [],
      mentorEvents: [],
      invitations: [],
      incubators: [],
      startupincubators: [],
      startupOrgs: [],
      selectedIndex: null,
      userID: getUserData().id,
      mutationLoading: false,
      selectedOrgId: null,
      isUnderUserOrgs: !this.getIncubatorFromSubdomain(),
      userOrgs: getUserData()
        .associatedOrgDetails

        .map(el => el.organization_id)
        .filter(n => n),
    }
  },
  methods: {
    beginDate(date) {
      const dateNew = new Date(date)
      const options = { day: 'numeric', month: 'long', year: 'numeric' }
      const formattedDate = dateNew.toLocaleDateString('en-US', options)
      return formattedDate
    },
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    },
    joinIncubator(incubatorId) {
      this.mutationLoading = true
      const mutation = gql`
        mutation {
          insert_users_associationtable_one(
            object: {
              user_id: ${getUserData().id},
              incubator_id: ${incubatorId},
              role: "mentor",
            }
          ) {
            id
          }
        }
      `
      this.$apollo.mutate({
        mutation,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully joined incubator\'s network.',
              icon: 'CompassIcon',
              variant: 'success',
            },
          })

          this.mutationLoading = false
          this.$apollo.queries.incubators.refetch()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong. Please try again later.',
              message: error.message,
              icon: 'CompassIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
        })
    },
    updateResponse(status) {
      this.mutationLoading = true
      const stmt = [`
            update_users_associationtable_by_pk(pk_columns: {id: ${this.programs[this.selectedIndex].users_associationtables[0].id}}, _set: {status: "${status ? 'Accept' : 'Reject'}"}) {
              id
              status
            }`]
      // Update mentor table if accepted
      if (status === true) {
        stmt.push(`
            insert_programs_partnerstable_one(object: {user_id: ${this.userID}, program_id: ${this.programs[this.selectedIndex].id}, designation: ${this.programs[this.selectedIndex].users_associationtables[0].designation} }) {
              id
            }`)
      }
      this.$apollo.mutate({
        mutation: gql`mutation {${stmt.join('')}}`,
        update: (store, { data: { update_users_associationtable_by_pk } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: update_users_associationtable_by_pk.id ? 'Your response has been recorded' : 'Failed to submit response',
              icon: update_users_associationtable_by_pk.id ? 'CheckIcon' : 'XIcon',
              variant: update_users_associationtable_by_pk.id ? 'success' : 'danger',
            },
          })
          this.$apollo.queries.programs.refetch()
          window.location.reload()
          this.mutationLoading = false
        },
      })
    },
    updateInvResponse(status) {
      this.mutationLoading = true
      const stmt = [`
            update_users_associationtable_by_pk(pk_columns: {id: ${this.invitations[this.selectedIndex].users_associationtables[0].id}}, _set: {status: "${status ? 'Accept' : 'Reject'}"}) {
              id
              status
            }`]
      // Update mentor table if accepted
      if (status === true) {
        stmt.push(`
            insert_programs_partnerstable_one(object: {user_id: ${this.userID}, program_id: ${this.invitations[this.selectedIndex].id}, designation: ${this.invitations[this.selectedIndex].users_associationtables[0].designation}, is_jury: ${this.invitations[this.selectedIndex].users_associationtables[0].is_jury} }) {
              id
            }`)
      }
      this.$apollo.mutate({
        mutation: gql`mutation {${stmt.join('')}}`,
        update: (store, { data: { update_users_associationtable_by_pk } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: update_users_associationtable_by_pk.id ? 'Your response has been recorded' : 'Failed to submit response',
              icon: update_users_associationtable_by_pk.id ? 'CheckIcon' : 'XIcon',
              variant: update_users_associationtable_by_pk.id ? 'success' : 'danger',
            },
          })
          this.$apollo.queries.programs.refetch()
          this.$apollo.queries.invitations.refetch()
          window.location.reload()
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    programs: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
          {
          programs_basicinfo(order_by: {id: desc}, limit: 3, where: {users_organizationtable: {domain: {_regex: "${subdomain}"}}, type: {_in: ["Pre-incubation Program", "Incubation Program", "Accelerator Program"]}, programs_startupparticipants: {programs_applicantstable: {organization_id_id: {_in: [${this.userOrgs}]}}}}) {
            id
            title
            begin_date
            capacity
            industry
            fund_raising_assistance
            description
            status
            type
            users_organizationtable {
              logo
              title
              type
            }
          }
        }`
      },
      update: data => data.programs_basicinfo,
    },
    applynow: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
        {
          programs_basicinfo(order_by: {id: desc}, limit: 3, where: {users_organizationtable: {domain: {_regex: "${subdomain}"}}, programs_applicationformstables: {status: {_eq: "enabled"}, round_number: {_eq: 1}}, type: {_in: ["Pre-incubation Program", "Incubation Program", "Accelerator Program"]}}) {
            begin_date
            capacity
            description
            id
            image
            industry
            title
            type
            programs_applicationformstables(where: {round_number: {_eq: 1}, status: {_eq: "enabled"}}) {
              id
            }
          }
        }`
      },
      update: data => data.programs_basicinfo,
    },
    applications: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
        {
            programs_basicinfo(order_by: {id: desc}, limit: 3, where: {users_organizationtable: {domain: {_regex: "${subdomain}"}}, type: {_in: ["Pre-incubation Program", "Incubation Program", "Accelerator Program"]}, programs_applicantstables: {organization_id_id: {_in: [${this.userOrgs}]}}}) {
            id
            begin_date
            capacity
            description
            image
            industry
            organization_id_id
            title
            total_shortlisting_rounds
            type
          }
        }`
      },
      update: data => data.programs_basicinfo,
    },
    mentorPrograms: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
        query getPrograms  {
            programs_basicinfo( order_by: {id: desc}, limit: 3, where: {is_program: {_eq: true},users_organizationtable: {domain: {_regex: "${subdomain}"}}, programs_partnerstables: { user_id: {_eq: ${getUserData().id} }}}) {
                id
                title
                begin_date
                capacity
                description
                industry
                type
                total_shortlisting_rounds
                status
                users_organizationtable {
                title
                url
                  }
                programs_partnerstables(where: {user_id: {_eq: ${getUserData().id}}}) {
                  id
                  is_jury
                }
              }
            }`
      },
      update: data => data.programs_basicinfo,
    },
    mentorEvents: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
        query getEvents {
            programs_basicinfo(order_by: {id: desc}, limit: 3, where: {is_event: {_eq: true},users_organizationtable: {domain: {_regex: "${subdomain}"}}, programs_partnerstables: { user_id: {_eq: ${getUserData().id} }}}) {
                id
                title
                begin_date
                capacity
                description
                industry
                type
                total_shortlisting_rounds
                status
                users_organizationtable {
                title
                url
                  }
                programs_partnerstables(where: {user_id: {_eq: ${getUserData().id}}}) {
                  id
                  is_jury
                }
              }
            }`
      },
      update: data => data.programs_basicinfo,
    },
    mentorCompetitions: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
         query getCompetitions {
            programs_basicinfo(order_by: {id: desc}, limit: 3, where: {is_competition: {_eq: true},users_organizationtable: {domain: {_regex: "${subdomain}"}}, programs_partnerstables: { user_id: {_eq: ${getUserData().id} }}}) {
                id
                title
                begin_date
                capacity
                description
                industry
                type
                total_shortlisting_rounds
                status
                users_organizationtable {
                title
                url
                  }
                programs_partnerstables(where: {user_id: {_eq: ${getUserData().id}}}) {
                  id
                  is_jury
                }
                 programs_deliverablelisttables {
                deliverable
                }
              }
            }`
      },
      update: data => {
        const res = data.programs_basicinfo.map(program => {
          const modifiedProgram = { ...program }
          modifiedProgram.deliverable = program.programs_deliverablelisttables.map(deliverable => deliverable.deliverable)
            .join(', ')
          return modifiedProgram
        })
        return res
      },
    },
    invitations: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
        {
            programs_basicinfo(order_by: {id: desc}, limit: 3, where: {users_organizationtable: {domain: {_regex: "${subdomain}"}}, users_associationtables: {user_id: {_eq: ${this.userID} }}}) {
              begin_date
              capacity
              id
              industry
              description
              type
              title
              status
              users_organizationtable {
                title
                url
              }
              users_associationtables(where: {user_id: {_eq: ${this.userID} }}) {
                id
                designation
                status
                role
                is_jury
              }
            }
          }`
      },
      update: data => {
        data.programs_basicinfo.forEach(program => {
          // eslint-disable-next-line no-unused-expressions,no-param-reassign
          program.users_associationtables[0].readonly = !(program.users_associationtables[0].status.toLowerCase() === 'invited')
        })
        return data.programs_basicinfo
      },
    },
    incubators: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
          query Incubators {
            users_organizationtable(where: {domain: {_regex: "${subdomain}"}, type: {_ilike: "incubator"}}) {
              id
              title
              official_email
              official_phone
              url
              city
              state
              industry
              startup_stage
              description
              usersAssociationtablesByAssociatedOrg(where: {user_id: {_eq: ${getUserData().id} }}) {
                id
                role
                status
              }
            }
          }`
      },
      update: data => {
        if (!data.users_organizationtable.usersAssociationtablesByAssociatedOrg) {
          // this.$bvModal.show('join-incubator-modal')
        }
        return data.users_organizationtable
      },
    },
    startupincubators: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
          query Incubators {
            users_organizationtable(where: {type: {_ilike: "incubator"}, domain: {_regex: "${subdomain}"}}) {
              id
              title
              official_email
              official_phone
              url
              city
              state
              industry
              startup_stage
              description
              usersAssociationtablesByAssociatedOrg(where: {organization_id: {_eq: ${this.selectedOrgId} }}) @include(if: ${!!this.selectedOrgId}) {
                id
                role
                status
              }
            }
          }`
      },
      update: data => data.users_organizationtable,
    },
    startupOrgs: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
          query startup_organizations {
          users_organizationtable(where: {users_associationtables: { role: {_eq: "startup"}, incubator_id: {_is_null: true}, user_id: {_eq: "${getUserData().id}"}}})
            {
              id
              title
              users_associationtables(where: {usersOrganizationtableByIncubator: {domain: {_regex: "${subdomain}"}}, role: { _in: ["startup","incubatee","preincubatee","graduate"] }}) {
                id
              }
            }
        }`
      },
      update: data => {
        const temp = []
        data.users_organizationtable.forEach(orgs => {
          if (orgs.users_associationtables.length > 0) {
            temp.push(orgs)
          }
        })
        return temp
      },
    },
  },
}
</script>

<style lang="scss">

@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
